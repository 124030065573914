<template>
  <b-row class="mt-2">
    <b-col
      cols="12"
      md="4"
    >
      <sector />
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <activity />
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <meet />
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <users />
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <country />
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <city />
    </b-col>
    <b-col
      class="text-center"
      cols="12"
    >
      <b-button
        variant="primary"
        @click="filterAction"
      >
        <FeatherIcon icon="FilterIcon" /> Filtrele
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import Sector from '@/views/Admin/Customers/FilterData/Sector.vue'
import Activity from '@/views/Admin/Customers/FilterData/Activity.vue'
import Meet from '@/views/Admin/Customers/FilterData/Meet.vue'
import Country from '@/views/Admin/Customers/FilterData/Country.vue'
import City from '@/views/Admin/Customers/FilterData/City.vue'
import Users from '@/views/Admin/Customers/FilterData/Users.vue'

export default {
  name: 'FilterData',
  components: {
    Users,
    BRow,
    BCol,
    BButton,
    Sector,
    Activity,
    Meet,
    Country,
    City,
  },
  props: {
    filterAction: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
